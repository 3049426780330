<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="pr-2 pl-2 mt-4">
      <v-row>
        <v-col
          class="text-button align-self-center text-center"
          cols="1"
        >
          <span class="font-weight-bold">{{ $t("messages.date") }}</span>
        </v-col>
        <v-col
          cols="10"
          sm="6"
          md="4"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              locale="ja-jn"
              no-title
              scrollable
              :max="maxDate"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                {{ $t('messages.cancel') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                {{ $t('messages.ok') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-end">
        <v-col
          class="text-button align-self-center text-center"
          cols="10"
          sm="6"
          md="4"
        >
          <div class="align-self-center">
            <v-text-field
              v-model="searchFactory"
              dense
              single-line
              outlined
              clearable
              :placeholder="$t('placeholder.FactoryNameId')"
              @keydown.enter.native="onSearchEmployeeManageId()"
            />
          </div>
        </v-col>
        <v-col
          class="text-button"
          cols="1"
        >
          <v-btn
            block
            color="accent"
            class="text-h4"
            @click="onSearchEmployeeManageId()"
          >
            {{ $t("messages.search") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="factoryOrders"
        :options.sync="options"
        :items-per-page="10"
        :server-items-length="totalItems"
        stle
        :loading-text="$t('table.messages.loading')"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 100],
          showFirstLastPage: false,
          'items-per-page-text': $t('table.messages.items_per_page')
        }"
        :no-data-text="$t('table.messages.no_data')"
      >
        <template
          v-slot:footer.page-text
          class="mr-0"
        >
          {{ $t("table.messages.page") }}
          {{ options.page }}
        </template>
        <template v-slot:item="props">
          <tr>
            <td
              class="text-center"
              @click="
                openDialog(
                  props.item.customer.id,
                  props.item.customer.user.name,
                  props.item.customer.code
                )
              "
            >
              {{ getIndex(props.index) }}
            </td>
            <td
              class="text-center"
              @click="
                openDialog(
                  props.item.customer.id,
                  props.item.customer.user.name,
                  props.item.customer.code
                )
              "
            >
              {{ props.item.customer.code }}
            </td>
            <td
              class="text-center"
              @click="
                openDialog(
                  props.item.customer.id,
                  props.item.customer.user.name,
                  props.item.customer.code
                )
              "
            >
              <span
                v-if="
                  props.item.totalOrderOfWorker === props.item.totalWorker
                "
              >
                {{ props.item.customer.user.name }}
              </span>
              <span
                v-else
                class="orange--text"
              >
                {{ props.item.customer.user.name }}
              </span>
            </td>
            <td
              class="text-center"
              @click="
                openDialog(
                  props.item.customer.id,
                  props.item.customer.user.name,
                  props.item.customer.code
                )
              "
            >
              {{
                props.item.totalOrderOfWorker
                  ? props.item.totalOrderOfWorker
                  : 0
              }}
            </td>
            <td
              class="text-center"
              @click="
                openDialog(
                  props.item.customer.id,
                  props.item.customer.user.name,
                  props.item.customer.code
                )
              "
            >
              {{
                props.item.totalWorker ? props.item.totalWorker : 0
              }}
            </td>
            <td
              class="text-center"
              @click="
                openDialog(
                  props.item.customer.id,
                  props.item.customer.user.name,
                  props.item.customer.code
                )
              "
            >
              <span>
                <span :class="getDifferencep(props.item.differenceWork)">
                  {{ props.item.differenceWork > 0 ? `+${props.item.differenceWork}`: `${props.item.differenceWork}` }}
                </span>
              </span>
            </td>
            <td class="text-center">
              <span>
                <v-btn
                  v-if="props.item.customer.pinTask.id"
                  class="mx-2"
                  fab
                  dark
                  outlined
                  small
                  color="primary"
                  @click="handleTag(props.item.customer.id)"
                >
                  <v-icon dark>
                    mdi-pin
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  class="mx-2"
                  fab
                  dark
                  outlined
                  small
                  color="grey"
                  @click="handleTag(props.item.customer.id)"
                >
                  <v-icon dark>
                    mdi-pin-off
                  </v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="800px"
    >
      <v-card>
        <div
          v-if="date >= maxDate"
          class="box_edit"
        >
          <v-btn
            class="mx-2"
            fab
            dark
            outlined
            small
            color="primary"
            @click="isEdit = !isEdit"
          >
            <v-icon
              v-if="isEdit"
              dark
            >
              mdi-pencil-off
            </v-icon>
            <v-icon
              v-else
              dark
            >
              mdi-pencil
            </v-icon>
          </v-btn>
        </div>
        <v-card-title class="d-flex justify-center mt-5">
          <span class="text-h5">
            <v-row>
              <v-col
                cols="6"
                sm="6"
                class="text-button text-center"
                md="6"
              >
                <v-row>
                  <v-col
                    cols="4"
                    sm="4"
                    class="text-button text-center"
                    md="4"
                  >
                    <span class="font-weight-bold primary--text">工場番号</span>
                  </v-col>
                  <v-col
                    cols="8"
                    sm="8"
                    md="8"
                  >
                    <v-text-field
                      :value="factoryCode ? factoryCode : ''"
                      dense
                      single-line
                      outlined
                      disabled
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                class="text-button text-center"
                md="6"
              >
                <v-row>
                  <v-col
                    cols="4"
                    sm="4"
                    class="text-button text-center"
                    md="4"
                  >
                    <span class="font-weight-bold primary--text">{{
                      $t("messages.customerName")
                    }}</span>
                  </v-col>
                  <v-col
                    cols="8"
                    sm="8"
                    md="8"
                  >
                    <v-text-field
                      :value="factoryName ? factoryName : ''"
                      dense
                      single-line
                      outlined
                      disabled
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </span>
        </v-card-title>
        <v-card-title>
          <v-row>
            <v-col
              class="text-button align-self-center text-center mt-3"
              cols="12"
              sm="12"
              md="12"
            >
              <div class="align-self-center">
                <v-text-field
                  v-model="searchJob"
                  dense
                  single-line
                  outlined
                  clearable
                  :placeholder="$t('placeholder.jobName')"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="4"
                sm="4"
                class="text-button text-center"
                md="4"
              >
                <span class="font-weight-bold color_detail_order">ジョブ</span>
              </v-col>
              <v-col
                cols="2"
                sm="2"
                class="text-button text-center"
                md="2"
              >
                <span
                  class="font-weight-bold color_detail_order"
                >注文人数</span>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                class="text-button text-center"
                md="3"
              >
                <span
                  class="font-weight-bold color_detail_order"
                >予定人数</span>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                class="text-button text-center"
                md="3"
              >
                <span class="font-weight-bold color_detail_order">不足</span>
              </v-col>
            </v-row>
            <v-row
              v-for="(job, index) in dessert"
              :key="index"
            >
              <v-col
                cols="4"
                sm="4"
                class="text-button text-center"
                md="4"
              >
                <span class="font-weight-bold">{{ job.name }}</span>
              </v-col>
              <v-col
                v-if="date >= maxDate"
                cols="2"
                sm="2"
                md="2"
                class="text-button text-center"
              >
                <v-text-field
                  v-if="isEdit"
                  ref="job"
                  :value="job.jobOrderInDay ? job.jobOrderInDay : 0"
                  dense
                  type="number"
                  min="0"
                  single-line
                  outlined
                  @change="
                    handleChangeJob(
                      $event,
                      job.customerId,
                      job.jobId,
                      job.jobOrderInDay,
                      index
                    )
                  "
                />
                <span
                  v-else
                  class="font-weight-bold"
                >
                  {{ job.jobOrderInDay ? job.jobOrderInDay : 0 }}
                </span>
              </v-col>
              <v-col
                v-else
                cols="2"
                sm="2"
                md="2"
                class="text-button text-center"
              >
                <span class="font-weight-bold">
                  {{ job.jobOrderInDay ? job.jobOrderInDay : 0 }}
                </span>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                class="text-button text-center"
                md="3"
              >
                <span class="font-weight-bold">{{
                  job.requiredNumberOfWorker ? job.requiredNumberOfWorker : 0
                }}</span>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                class="text-button text-center"
                md="3"
              >
                <span class="font-weight-bold">
                  <span v-if="job.requiredNumberOfWorker > job.jobOrderInDay">
                    <span
                      class="differencep_plus"
                    >+{{
                      defference(
                        job.requiredNumberOfWorker,
                        job.jobOrderInDay
                      )
                    }}</span>
                  </span>
                  <span
                    v-else-if="job.requiredNumberOfWorker < job.jobOrderInDay"
                  >
                    <span
                      class="differencep_minus"
                    >-{{
                      defference(
                        job.jobOrderInDay,
                        job.requiredNumberOfWorker
                      )
                    }}</span>
                  </span>
                  <span v-else>
                    0
                  </span>
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="4"
                sm="4"
                md="4"
                class="text-button text-center color_detail_order font-weight-bold"
              >
                <span>合計</span>
              </v-col>
              <v-col
                cols="2"
                sm="2"
                class="text-button text-center"
                md="2"
              >
                <span class="font-weight-bold">
                  {{ totalJobOrderInDay ? totalJobOrderInDay : 0 }}
                </span>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                class="text-button text-center"
                md="3"
              >
                <span class="font-weight-bold">
                  {{ totalWorker ? totalWorker : 0 }}
                </span>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                class="text-button text-center"
                md="3"
              >
                <span v-if="totalWorker > totalJobOrderInDay">
                  <span class="differencep_plus">
                    +{{
                      defference(totalWorker, totalJobOrderInDay)
                    }}</span>
                </span>
                <span v-else-if="totalWorker < totalJobOrderInDay">
                  <span
                    class="differencep_minus"
                  >-{{
                    defference(totalJobOrderInDay, totalWorker)
                  }}</span>
                </span>
                <span v-else>
                  0
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="isEdit">
          <v-row
            v-if="date >= maxDate"
            class="d-flex justify-center mb-3"
          >
            <v-col cols="3">
              <v-btn
                block
                color="primary"
                @click="handleUpdateJob(factoryId)"
              >
                <span class="text-h5 font-weight-bold white--text">{{
                  $t("messages.update")
                }}</span>
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn
                block
                color="grey"
                @click="dialog = false"
              >
                <span class="text-h5 font-weight-bold white--text">{{
                  $t("messages.close")
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'

  export default {
    name: 'FactoryWork',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        maxDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        dateJob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        menu: false,
        menuJob: false,
        modal: false,
        menu2: false,
        searchFactory: '',
        searchJob: '',
        options: {},
        currentOptions: {},
        loading: false,
        headers: [],
        factoryOrders: [],
        dessert: [],
        dialog: false,
        arrUpdateJobs: [],
        pins: [],
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        factoryName: '',
        factoryId: '',
        factoryCode: '',
        totalJobOrderInDay: 0,
        totalWorker: 0,
        idCustomer: 0,
        totalItems: 0,
        isEdit: false,
      }
    },
    computed: {
      ...get('order', ['orders', 'message', 'status', 'error', 'jobs']),
    },
    watch: {
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      orders (value) {
        if (value) {
          if (
            value.data.count <= this.options.itemsPerPage &&
            this.options.page !== 1
          ) {
            this.options.page = 1
            return
          }
          this.totalItems = value.data.count
          this.factoryOrders = value.data.order.map(e => {
            const differenceWork = this.defference(e.totalWorker - e.totalOrderOfWorker)
            return { ...e, differenceWork }
          })
          this.pins = value.data.pin
        }
      },
      dialog (value) {
        this.searchJob = ''
        this.dateJob = this.date
        if (!value) {
          this.isEdit = false
          this.dessert = []
          this.arrUpdateJobs = []
        }
      },
      message (value) {
        if (value === 'updateSuccess') {
          this.showSnackBar('messages.updateSuccess')
        }
      },
      jobs (value) {
        let totalJobOrderInDay = 0
        let totalWorker = 0
        if (value) {
          this.dessert = value.data.jobsInTime
          value.data.jobsInTime.forEach(job => {
            totalJobOrderInDay += job.jobOrderInDay
            totalWorker += job.requiredNumberOfWorker
          })
          this.totalJobOrderInDay = totalJobOrderInDay
          this.totalWorker = totalWorker
        }
      },
      date () {
        this.initialize()
      },
      searchJob () {
        const payload = {
          customerId: this.idCustomer,
          time: this.date,
          keyword: this.searchJob,
        }
        this.$store.dispatch('order/getOrdersForCompany', payload)
      },
      searchFactory (value) {
        if (!value) {
          this.initialize()
        }
      },
    },
    mounted () {
      this.arrUpdateJobs = []
      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'center',
          value: 'index',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
        },
        {
          text: this.$t('table.headers.factoryNumber'),
          align: 'center',
          value: 'userName',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
        },
        {
          text: this.$t('messages.factoryName'),
          align: 'center',
          value: 'name',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
        },
        {
          text: this.$t('table.headers.totalOrder'),
          align: 'center',
          value: 'total_order',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
        },
        {
          text: this.$t('table.headers.totalActualNumber'),
          align: 'center',
          value: 'total_actual_number',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
        },
        {
          text: this.$t('table.headers.difference'),
          align: 'center',
          value: 'difference',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
        },
        {
          text: this.$t('table.headers.active'),
          align: 'center',
          value: 'active',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
        },
      ]
    },
    methods: {
      initialize () {
        let time = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
        const tmpOptions = { ...this.options }
        tmpOptions.limit = tmpOptions.itemsPerPage
        if (this.searchFactory) {
          tmpOptions.keyword = this.searchFactory.trim()
        }

        if (this.date) {
          time = this.date
        }
        tmpOptions.time = time

        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('order/getOrders', tmpOptions)
      },
      openDialog (customerId, customerName, customerCode) {
        const payload = {
          customerId: customerId,
          time: this.date,
        }
        this.idCustomer = customerId
        this.$store.dispatch('order/getOrdersForCompany', payload)
        this.factoryName = customerName
        this.factoryId = customerId
        this.factoryCode = customerCode
        this.dialog = !this.dialog
      },
      onSearchEmployeeManageId () {
        if (this.options.page !== 1) {
          this.options.page = 1
        } else {
          this.initialize()
        }
      },
      handleTag (customerId) {
        const payload = {
          customerId: customerId,
          time: this.date,
        }
        this.$store.dispatch('order/updatePinTask', payload)
        setTimeout(() => {
          this.initialize()
        }, 500)
      },
      handleChangeJob (value, customerId, jobId, oldValue, index) {
        if (value >= 0) {
          const arrJob = {
            jobId: jobId,
            value: value,
          }
          if (parseInt(value) !== oldValue) {
            this.arrUpdateJobs = this.arrUpdateJobs.filter(
              item => item.jobId !== arrJob.jobId,
            )
            this.arrUpdateJobs.push(arrJob)
          } else {
            this.arrUpdateJobs = this.arrUpdateJobs.filter(
              item => item.jobId !== arrJob.jobId,
            )
          }
        } else {
          this.$refs.job[index].$refs.input.value = oldValue
        }
      },
      handleUpdateJob (customerId) {
        if (this.arrUpdateJobs.length !== 0) {
          this.arrUpdateJobs.forEach(job => {
            const payload = {
              customerId: customerId,
              jobId: job.jobId,
              date: this.date,
              jobOrderInDay: job.value,
            }
            this.$store.dispatch('order/updateJob', payload)
          })
          setTimeout(() => {
            this.initialize()
          }, 500)
        }
        this.dialog = !this.dialog
        this.isEdit = false
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      checkPin (customerId) {
        let check = false
        if (this.pins.includes(customerId)) {
          check = true
        }
        return check
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      defference (total1 = 0, total2 = 0) {
        return total1 - total2
      },
      getDifferencep (value) {
        if (!value) return ''
        return value < 0 ? 'differencep_minus' : 'differencep_plus'
      },
    },
  }
</script>

<style scoped lang="sass">
.differencep_plus
  color: #33c4be
.differencep_minus
  color: #e43e08
.color_detail_order
  color: #8ab9f6
.v-card
  position: relative
.box_edit
  position: absolute
  top: 10px
  right: 5px
</style>
